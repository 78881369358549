/*==========================================
変数の登録
==========================================*/
// jQueryを読み込み
import $ from 'jquery';

import Swiper from 'swiper'; //sliderの設定（swiper）
import slick from 'slick-carousel'; //sliderの設定（slick）

require("jquery-mousewheel");
require('malihu-custom-scrollbar-plugin');

const _ = require('underscore');


var pcSize = 768; //windowサイズ(PC時)
var spSize = 767; //windowサイズ(SP時)
var wW = window.innerWidth;  //windowサイズの取得



/*------------------------------------
  ブレイクポイント判別
------------------------------------*/
var breakpointflag = false;

$(window).on('load resize',function(){
  breakpointflag = ( window.matchMedia('(max-width:'+ spSize +'px)').matches );
});
function breakpointFlagF(){
  return breakpointflag;
}

/*------------------------------------
  Safariアドレスバー高さ対策
  cssにdocumentElement.style.setPropertyを登録
------------------------------------*/
$(window).on('load resize',function(){
  // 最初に、ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
  var vh = window.innerHeight * 0.01;
  // カスタム変数--vhの値をドキュメントのルートに設定
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


/*==========================================
  横幅の取得
==========================================*/
var wWSite = $(window).width();
$(window).on('resize',function(){
  wW = window.innerWidth;
  wWSite = $(window).width();
});

var wrap = 'body'; //body要素
var windowBg = '.windowBg'; //windowBg(背景)


/*------------------------------------
  パラメーターを取得
------------------------------------*/
var locationflag = new Object;
var arg01 = new Object;
var pair01 = "";

$(function(){
  // 変数pairにURLの?の後ろを&で区切ったものを配列にして代入
  var pair01 = location.search.substring(1).split('&');

  // for文でrairがある限りループさせる
  for( var i = 0; pair01[i];i++) {

  　　// 変数kvにpairを=で区切り配列に分割する
      var kv = pair01[i].split('=');// kvはkey-value

  　　// 最初に定義したオブジェクトarg01に連想配列として格納
      arg01[kv[0]] = kv[1];  // kv[0]がkey,kv[1]がvalue
  }
});

//パラメーターのフラグを受け渡す
function paramFlagF(){
  locationflag = [ arg01, pair01 ];
  return locationflag;
}





/*==========================================
  ページ認識時に、処理すべきもの
==========================================*/
$(function () {
  //NEWアイコン表示期間（日数）
  ;(function(){
    var NEW_SHOW_TERM = 90;

    //spanタグを取得
    if($('.newIcon').length){
      const $spans = $('.newIcon');

      //データ属性（date）と現在の日付を比較して表示期間内なら「new」クラスを付与
      $spans.each(function (i, elm) {
        var oldDate = new Date($(elm).data('date'));
        var newDate = new Date();
        var n = (newDate - oldDate) / (1000 * 60 * 60 * 24);

        if (n <= NEW_SHOW_TERM){
            $(elm).addClass("new");
        }
      });
    }
  })();

  ;(function(){
    //object-fit pollyfill(IE対策)
    const ua = navigator.userAgent;
    if(ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1){
      objectFitImages();
    }
  })();

  /*==========================================
    スムーズスクロール リンクのクラスがnoJump以外の時
  ==========================================*/
  $('a[href^="#"]').on('click', function(e){
    if( !$(this).hasClass('noJump')){
      var speed = 800;
      var href= $(this).attr('href');
      var target = $(href == '#' || href == '' ? 'html' : href);
      var position = target.offset().top;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
    }
    e.preventDefault();
  });


  /*==========================================
  スムーズスクロール is-contentAnchorList
  ==========================================*/
  $('.is-contentAnchorList a').on('click', function(e){
  var speed = 800;
  var href= $(this).attr('href');
  var target = $(href == '#' || href == '' ? 'html' : href);
  var targetTop = Number(target.css('padding-top').replace('px',''));
  var position = target.offset().top;
  position = position + targetTop * 0.5;

  if(($(this).parent().index() == 0) || (target.hasClass("is-fadeFlag") && !target.hasClass("is-fadeOn"))){
    if(pcSize <= $(window).width()){
      position -= 30;
    }else{
      position -= 20;
    }
  }
  $('body,html').animate({scrollTop:position}, speed, 'swing');

  e.preventDefault();
  });


  /*
  検索エリア ,is-selectPullがあるとき
  Reference: http://jsfiddle.net/BB3JK/47/
  検索ボタンselectをデザイン
  */
  ;(function(){
    $('.is-selectPull').each(function(){
      var $this = $(this), numberOfOptions = $(this).children('option').length;


      $this.addClass('select-hidden');
      $this.wrap('<div class="is-selectArea"></div>');
      $this.after('<div class="select-styled is-first-styled"></div>');

      var classText = $this.attr('class').split(' ');

      var $thisWrap = $this.parents('.is-selectArea');
      _.each(classText,function(elm,i){
        $thisWrap.addClass(elm);
      });
      var $styledSelect = $this.next('div.select-styled');
      $styledSelect.text($this.children('option').eq(0).text());

      var $list = $('<ul />', {
          'class': 'select-options'
      }).insertAfter($styledSelect);

      for (var i = 0; i < numberOfOptions; i++) {
          $('<li />', {
              text: $this.children('option').eq(i).text(),
              rel: $this.children('option').eq(i).val()
          }).appendTo($list);
      }

      var $listItems = $list.children('li');

      $styledSelect.click(function(e) {
          e.stopPropagation();
          $('div.select-styled.active').not(this).each(function(){
              $(this).removeClass('active').next('ul.select-options').hide();
          });
          $(this).toggleClass('active').next('ul.select-options').toggle();
      });

      $listItems.click(function(e) {
          e.stopPropagation();
          if($(this).index() == 0 &&
          ! $styledSelect.hasClass('is-first-styled') ){
            $styledSelect.addClass('is-first-styled');
          }else if($(this).index() !== 0 && $styledSelect.hasClass('is-first-styled')){
            $styledSelect.removeClass('is-first-styled');
          }else{}

          $styledSelect.text($(this).text()).removeClass('active');
          $this.val($(this).attr('rel'));

          $list.hide();
      });

      $(document).click(function() {
          $styledSelect.removeClass('active');
          $list.hide();
      });
    });
  })();


});



/*==========================================
  全ての要素をロード後に、処理すべきもの
==========================================*/
$(window).on('load', function(){

  /*==========================================
    FadeFun スクリールによる要素のfade
  ==========================================*/
  /*
    １　fadeさせる要素
    2  追加されるクラス名
    3　fadeさせる要素が画面に出てから〇px余白を持たせるか
    4, ロード後の遅延
    new fadeFun( 1, 2, 3, 4 );
  */
  if($(".is-fadeFlag").length){
    new FadeFun($(".is-fadeFlag"), "is-fadeOn", 0, 10);
  }


  /*==========================================
    FadeFunLeft スクリールによる要素の左からfade
  ==========================================*/
  /*
    1　fadeさせる要素
    2  fadeされる要素の子要素(左～表示する要素)
    3  fadeされる要素の子要素に追加されるクラス名
    4　fadeさせる要素が画面に出てから〇px余白を持たせるか
    5　FadeFunの遅延タイミングを考慮した、測定時の遅延
    6　左から順にfadeさせる遅延タイミング
    new FadeFunLeft( 1, 2, 3, 4, 5, 6 );
  */
  if($(".is-fadeFlagLeft").length){
    new FadeFunLeft($(".is-fadeFlagLeft"), ".is-fadeFlagLeftItem", "is-fadeFlagLeftOn", 0, 10, 180);
  }

  /*==========================================
    .l-slideHeaderNavInner
    スクロールバーのカスタマイズ
    mCustomScrollbar
  ==========================================*/
  ;(function(){
    // $(".headerNavInner").mCustomScrollbar({
    //   advanced:{
    //     updateOnContentResize: true
    //   }
    // });
  })();

  /*==========================================
    .is-scrollAreaSP
    スクロールバーのカスタマイズ
    mCustomScrollbar(PCはなし、SPあり)
  ==========================================*/
  ;(function(){
    if ($('.is-scrollAreaSP').length){

      scrollAreaSPScrollbar();
      $(window).on('resize',function(){
        scrollAreaSPScrollbar();
      });

      function scrollAreaSPScrollbar(){
        //PC
        if(wW > spSize) {
          $(".is-scrollAreaSP").mCustomScrollbar("destroy");

        //SP
        }else{
          $(".is-scrollAreaSP").mCustomScrollbar({
            horizontalScroll: true,
            advanced:{
              updateOnContentResize: true
            }
          });
        }
      }
    }
  })();

  /*==========================================
    #is-pageTopLink
    ページトップへ戻る
  ==========================================*/
  if($('#is-pageTopLink').length && $('.l-header').length && $('.contentsInner').length && $('.l-footer').length ){
    /*==========================================
      1) ページトップのボタン(該当タグの子要素は、高さ測定等で使用します。)
      2) ページスクロールで、通過したら表示になる地点
      3) leftの最大値を決めるために、基準になる横幅の要素
      4) ページスクロールで、画面に表示されたら、停止する地点
      5) 表示時に1）に追加されるクラス名
      6) 停止時に1）に追加されるクラス名
      new PageTop( 1, 2, 3, 4, 5, 6 );
    ==========================================*/
    new PageTop( $('#is-pageTopLink'), $('.l-header'), $('.contentsInner'), $('.l-footer'), 'is-fade', 'is-fix' );
  }




  if($('.is-dayTabList a').length && $('.is-dayTabListTarget').children().length){
    /*==========================================
      重要なお知らせ Tabパネル
      1) TAB切り替えを操作するリンク
      2) タブで切り替わる要素
      3) fadeの速度指定
      new TabData(1,2,3);
    ==========================================*/
    new TabData( $('.is-dayTabList a'),  $('.is-dayTabListTarget').children(), 600);


    //もしタブの切り替えるのhtmlの中にslickがある場合 再読み込み
    $('.is-dayTabList a').on('click',function(e){
      if($('.is-photoSideSlider').length){
        $('.is-photoSideSlider').slick('setPosition');
      }

      e.preventDefault();
    });
  }


  //グローバルナビの設定に関して
  /*==========================================
  .is-headerNav PC
  ==========================================*/
  ;(function(){
    if($('.is-headerNav').length){
      /*==========================================
      .is-headerNav PC SP共通
      ==========================================*/
      var headerNavBg = '.is-headerNavBg'; //背景
      var $headerNav = $('.is-headerNav'); //ナビ全体の要素
      var $openTarget = $('.is-haderNavBtn'); //クリックボタン
      var activeClass = 'is-active';
      var openClass = 'is-open';
      var headerNavSpeed = 700;

      var $slideHeaderWrapAll = $('.is-slideHeaderWrap *');

      //お客様確認用。本番時は削除が必要
      // $($openTarget.children('a')).click(function() {
      //   //pcの時のみhover処理
      //   if(breakpointflag == false) {
      //     if($($headerNav).is(":hidden")){
      //       $(wrap).append('<div class="windowBg is-fadeAnimation is-headerNavBg"></div>');
      //       $($headerNav).fadeIn(headerNavSpeed);
      //       $($headerNav).addClass(activeClass);
      //       $openTarget.addClass(openClass);
      //       // $('body').addClass('is-openBody');  
      //      }
      //   }

      //  }, function(){});
      //お客様確認用。不要時に削除が必要ここまで

      /*==========================================
      .is-headerNav
      追従ナビのメニュー表示・非表示
      ==========================================*/
      $('.is-haderNavBtn a').on('click',function(e){

        if($('.is-headerNav').length){
          //ナビが非表示の時
          if($($headerNav).is(":hidden")){
            //背景をbody内に追加
            $(wrap).append('<div class="windowBg is-fadeAnimation is-headerNavBg"></div>');



            //$headerNav表示
            $($headerNav).fadeIn(headerNavSpeed);
            //$headerNavにactiveClassを付与
            $($headerNav).addClass(activeClass);
            //$headerNavにopenClassを付与
            $openTarget.addClass(openClass);
            // $('body').addClass('is-openBody');  


          //ナビが表示の時
          }else{
            //headerNavBg非表示
            $(headerNavBg).removeClass('is-fadeAnimation').fadeOut( headerNavSpeed, function(){ $(this).remove(); });

            //headerNav　openClassの削除
            $openTarget.removeClass(openClass);

            //headerNav非表示
            $($headerNav).fadeOut(headerNavSpeed, function(){
              //headerNav　activeClassの削除

              $($headerNav).removeClass(activeClass);//headerクラス削除

              // $('body').removeClass('is-openBody');  

              $slideHeaderWrapAll.css({
                'display': ''
              }).removeClass(openClass).removeClass(activeClass);
            });




          }
        }
        e.preventDefault();
      });

      //headerNavBgクリック時　ナビの非表示
      $(wrap).on('click', headerNavBg, function(e){
        $(headerNavBg).removeClass('is-fadeAnimation').fadeOut( headerNavSpeed, function(){ $(this).remove(); });
        $($headerNav).fadeOut(headerNavSpeed);
        $($headerNav).removeClass(activeClass);
        $openTarget.removeClass(openClass);
        // $('body').removeClass('is-openBody');  
      });
    }

  })();


  /*==========================================
    .is-slideHeaderWrap
    SP時の追従ナビ　スワイプによる切り替え
  ==========================================*/
  ;(function(){
    var $slideHeaderWrap = $('.is-slideHeaderWrap'); //SP時のスライドする要素
    var slideHeaderClassName = 'is-active';//.is-slideNavクリック時に.is-slideHeaderWrapに付与するクラス
    var slideHeaderClassNameBefore = 'is-activeBefore';//.is-slideNavクリック時に前回.is-activeを付与した要素に付与するクラス
    var sppedSP = 400; //.is-slideNavクリック時のslideする速度

    /*==========================================
    .is-slideNav クリック時
    ==========================================*/
    $('.is-slideNav a').on('click',function(e){
      var thisHref = $(this).attr('href');　//リンクのhref取得
      var slideflag = false;　//slideflag　フラグの設定

      /*==========================================
      .is-slideNav の子要素の.slideHeaderClassNameBefore 全て削除
      ==========================================*/
      $slideHeaderWrap.children().removeClass(slideHeaderClassNameBefore);

      $.when(
        SlideNavFun()
      ).done(function() {
        var thisHrefLeft = $slideHeaderWrap.children('.' + slideHeaderClassName).length + $slideHeaderWrap.children('.' + slideHeaderClassNameBefore).length;

        $slideHeaderWrap.animate({ scrollLeft: thisHrefLeft * wW }, sppedSP, 'swing',function(){
          $slideHeaderWrap.children().removeClass(slideHeaderClassNameBefore);
        });
      });

      /*==========================================
        SlideNavFun
      ==========================================*/
      function SlideNavFun(){
        if($($slideHeaderWrap.children('.'+ slideHeaderClassName )).length){
          $($slideHeaderWrap.children('.'+ slideHeaderClassName )).addClass(slideHeaderClassNameBefore).removeClass(slideHeaderClassName);
           slideflag = true;
        }
        //slideflagがfalseの時
        if( slideflag == false){
          //$(thisHref)をslideHeaderWrap内の子要素の1番目の次に移動
          $(thisHref).insertAfter($slideHeaderWrap.children()[0]);
          //slideflagがfalseではないとき
        }else{
          //$(thisHref)をslideHeaderWrap内のslideHeaderClassNameBefore要素の後ろに移動
          $(thisHref).insertAfter($slideHeaderWrap.children('.'+ slideHeaderClassNameBefore));
        }
        //$(thisHref)にslideHeaderClassNameクラスを追加
        $(thisHref).addClass(slideHeaderClassName);
      }

      e.preventDefault();
    });

    //$($slideHeaderWrap)をスクロールしてるとき
    $($slideHeaderWrap).on('scroll',function(){
      //$slideHeaderWrap内を一番左にいる時
      if($slideHeaderWrap.scrollLeft() == 0){
        //$slideHeaderWrap内の子要素でslideHeaderClassNameのクラスを削除
        $($slideHeaderWrap.children()).each(function(){
          if($(this).hasClass(slideHeaderClassName)){
            $(this).removeClass(slideHeaderClassName);
          }
        });
      }
    });
  })();






  /*==========================================
    MoreBtn moreボタンクリック時の処理(クラス追加による表示)
  ==========================================*/
  /*
  　moreBtnのリンクのhrefの要素を非表示にします。
  　予め、2で指定した件数に関しては、最初から表示します。
    1) moreBtnのリンク指定
       リンクのhrefには、開閉させるに対象のID名を入力しましょう。
       例) <a class="noJump" href="#is-tvmoreList01">さらに見る</a>
    2) デフォルトで表示する件数

    new MoreBtn( 1, 2 );
  */
  if( $('.is-moreBtn').length ){
    new MoreBtn($('.is-moreBtn a'), 10);
  }
  /*==========================================
    MoreBtnSlide tvmoreBtnボタンクリック時の処理（スライド表示）
  ==========================================*/
  /*
  　MoreBtnSlideのリンクのhrefの要素を非表示にします。
  　予め、2で指定した件数に関しては、最初から表示します。
    1) MoreBtnSlideのリンクを指定します。
       リンクのhrefには、開閉させるに対象のID名を入力しましょう。
       例) <a class="noJump" href="#is-tvmoreList01">さらに見る</a>
    2) デフォルトで表示する件数
    3) スライド表示させる速度
    new MoreBtnSlide( 1, 2, 3 );
  */
  if( $('.is-tvmoreBtn').length ){
    new MoreBtnSlide($('.is-tvmoreBtn a'), 5, 500);
  }
  /*==========================================
    FurtherBtn FurtherBtnボタンクリック時の処理
  ==========================================*/
  /*
  　FurtherBtnのリンクのhrefの要素を非表示にします。
  　予め、2)で指定した件数に関しては、最初から表示します。
    クリックするたびに　3)で指定した個数分だけ表示します。

    1 FurtherBtnのリンク指定
    2 FurtherBtn　デフォルトで表示する件数
    3 FurtherBtn　クリック後に表示する件数
    4 spBtnNo　sp時ボタンなし 「true」
    new FurtherBtn( 1, 2, 3, 4 );
  */
  if( $('.is-furtherBtn a').length ){
    new FurtherBtn($('.is-furtherBtn a'), 9, 9, true);
  }



  /*==========================================
    .is-newsListBtn FurtherBtnボタンクリック時の処理
  ==========================================*/
  if( $('.is-newsListBtn a').length ){

    /*
    　FurtherBtnのリンクのhrefの要素を非表示にします。
    　予め、2)で指定した件数に関しては、最初から表示します。
      クリックするたびに　3)で指定した個数分だけ表示します。

      1 FurtherBtnのリンク指定
      2 FurtherBtn　デフォルトで表示する件数
      3 FurtherBtn　クリック後に表示する件数
      4 spBtnNo　sp時ボタン表示あり 「false」
      new FurtherBtn( 1, 2, 3, 4 );
    */
    new FurtherBtn($('.is-newsListBtn a'), 5, 5, false);

    //消されている要素にsliderがもしある場合
    $('.is-newsListBtn a').on('click', function(e){
      var target = $(this).attr('href');
      if($(target).find('.is-photoSideSlider').length){
        $(target).find('.is-photoSideSlider').slick('setPosition');
      }
      e.preventDefault();
    });
  }

  /*==========================================
    tvリスト FurtherBtnボタンクリック時の処理
  ==========================================*/
  if( $('.is-tvfurtherBtn a').length ){
    /*
    　FurtherBtnのリンクのhrefの要素を非表示にします。
    　予め、2)で指定した件数に関しては、最初から表示します。
      クリックするたびに　3)で指定した個数分だけ表示します。

      1 FurtherBtnのリンク指定
      2 FurtherBtn　デフォルトで表示する件数
      3 FurtherBtn　クリック後に表示する件数
      4 spBtnNo　sp時ボタンなし 「true」
      new FurtherBtn( 1, 2, 3, 4 );
    */
    new FurtherBtn($('.is-tvfurtherBtn a'), 6, 6, true );
  }


  /*==========================================
    FurtherBtn FurtherBtnボタンクリック時の処理
  ==========================================*/

  if( $('.is-tvdetailListBtn a').length ){
    /*
    　FurtherBtnのリンクのhrefの要素を非表示にします。
    　予め、2)で指定した件数に関しては、最初から表示します。
      クリックするたびに　3)で指定した個数分だけ表示します。

      1 FurtherBtnのリンク指定
      2 FurtherBtn　デフォルトで表示する件数
      3 FurtherBtn　クリック後に表示する件数
      4 spBtnNo　sp時ボタンあり 「false」
      new FurtherBtn( 1, 2, 3, 4 );
    */
    new FurtherBtn($('.is-tvdetailListBtn a'), 10, 10, false);
  }

  /*==========================================
    アコーディオンボタンの指定
  ==========================================*/
  if($('.is-accordionBtn a').length){
    /*==========================================
      1 アコーディオンするリンク指定
      2 アコーディオンするリンクに付与するクラス名
      3 アコーディオン 開閉速度
      new AccordionFun( 1, 2, 3 );
    ==========================================*/
    new AccordionFun($('.is-accordionBtn a'),'is-open',400);
  }

  /*==========================================
    サイドナビのアコーディオンの指定
  ==========================================*/
  if($('.is-accordionBtnTarget a').length){
  /*==========================================
    1 アコーディオンするリンク指定
    2 アコーディオン兄弟要素　実際にスライドする要素を指定
    3 アコーディオン開閉時に、付与するクラス名
    4 アコーディオン 開閉速度
    new AccordionBtnTargetFun( 1, 2, 3, 4 );
  ==========================================*/
    new AccordionBtnTargetFun($('.is-accordionBtnTarget a') ,'.is-faqTypeTitle','is-open',400);
  }

  /*==========================================
    サイドナビのアコーディオンの指定
  ==========================================*/
  if($('.is-faqTypeTitle a').length){
  /*==========================================
    1 アコーディオンするリンク指定
    2 アコーディオン兄弟要素　実際にスライドする要素を指定
    3 アコーディオン開閉時に、付与するクラス名
    4 アコーディオン 開閉速度
    new AccordionBtnTargetFun( 1, 2, 3, 4 );
  ==========================================*/
    new AccordionBtnTargetFun($('.is-faqTypeTitle a') ,'.is-faqList','is-open',400);
  }


  /*==========================================
    サイドナビのアコーディオンの指定
  ==========================================*/
  if($('.is-faqTypeTitle a').length){
  /*==========================================
    1 アコーディオンするリンク指定
    2 アコーディオン兄弟要素　実際にスライドする要素を指定
    3 アコーディオン開閉時に、付与するクラス名
    4 アコーディオン 開閉速度
    new AccordionBtnTargetFun( 1, 2, 3, 4 );
  ==========================================*/
    new AccordionBtnTargetFun($('.is-questionTitle a') ,'.is-answerTextArea','is-open',400);
  }

  if($('.is-headerProductTitle a').length){
    /*==========================================
      1 アコーディオンするリンク指定
      2 アコーディオン兄弟要素　実際にスライドする要素を指定
      3 アコーディオン開閉時に、付与するクラス名
      4 アコーディオン 開閉速度
      new AccordionBtnTargetFun( 1, 2, 3, 4 );
    ==========================================*/
    new AccordionBtnTargetFun($('.is-headerProductTitle a') ,'.is-headerProductItem','is-open',400);
  }


  /*==========================================
    .is-sideNavBubbleArea
  ==========================================*/
  // マウスポインターが.is-sideNavBubbleAreaのリンク内
  if(  $('.is-sideNavBubbleArea a').length ){
    $('.is-sideNavBubbleArea a').mouseover(function(e) {

      var $targetSideNavBubble = $(this).children(".is-sideNavBubble");
      var thisH = $(this).innerHeight();
      var parentH = $targetSideNavBubble.innerHeight();
      var bubbleTop = ( thisH  - parentH ) / 2;　//topの値

      //$targetSideNavBubbleのcss displayとtop
      $targetSideNavBubble.css({
        'display': "block",
        'top': bubbleTop
      });
      //$targetSideNavBubbleの表示
      setTimeout(function(){
        $targetSideNavBubble.stop().animate({
          "opacity": 1
        }, 200);
      }, 400);

    // マウスポインターが.is-sideNavBubbleAreaのリンク内から外れた時の動作
    }).mouseout(function(e) {
      var $targetSideNavBubble = $(this).children(".is-sideNavBubble");
      $targetSideNavBubble.css({
        "display": "",
        "top": "",
        "opacity": ""
      });
    });
  }

  /*==========================================
    .lightBoxItem
    lightBoxの設定（製品詳細メイン画像用）
  ==========================================*/
  ;(function(){

    //変数定義
    var elem = '.productSlider .productSliderItem';
    var lightBox = '.lightBox';
    var lightBg = '.is-lightBoxBg';
    var lightBoxInner = '.lightBoxInner';
    var lightBoxPage = '.lightBoxPage';
    var lightBoxBtn = '.lightBoxBtn';
    var lightBoxPrevBtn = '.lightBoxPrevBtn';
    var lightBoxNextBtn = '.lightBoxNextBtn';
    var lightBoxClick = '.is-lightBoxBg, .lightBoxBtn';
    var currentNo, itemCnt;

    if($(elem).length){
      //elemがクリックされたら
      $(elem).on('click', function(){
        currentNo = $(elem).index(this);
        itemCnt = $('.productSlider .slick-slide').not('.slick-cloned').length;
        
        openModal();
      });

      //lightBox表示中にlightBgがクリックされたら
      $(wrap).on('click', lightBoxClick, function(){
        //lightBgとlightBoxを削除する
        $(lightBg).removeClass('is-fadeAnimation').fadeOut('slow', function(){ $(this).remove(); });
        $(lightBox).fadeOut('slow', function(){ $(this).remove(); });
        $(lightBoxBtn).fadeOut('slow', function(){ $(this).remove(); });
        $(lightBoxPrevBtn).fadeOut('slow', function(){ $(this).remove(); });
        $(lightBoxNextBtn).fadeOut('slow', function(){ $(this).remove(); });
        //wrapのスクロールバーを表示する
        $(wrap).css('overflow', 'auto');
      });
    }

    //モーダルをオープン
    function openModal(){
      //wrapにlightBoxを追加する
      $(wrap).append('<div class="windowBg is-fadeAnimation is-lightBoxBg"></div>');
      $(wrap).append('<div class="lightBox"></div>');
      $(wrap).append('<p class="lightBoxBtn"></p>');
      $(lightBox).append('<div class="lightBoxInner"></div>');
      $(lightBox).append('<div class="lightBoxPage"></div>');
      $(wrap).append('<p class="lightBoxPrevBtn"></p>');
      $(wrap).append('<p class="lightBoxNextBtn"></p>');

      if(currentNo === itemCnt){
        currentNo = 0;
      }

      //戻るボタンクリック時
      $('.lightBoxPrevBtn').on('click', function(){
        prevImg()
      });

      //次へボタンクリック時
      $('.lightBoxNextBtn').on('click', function(){
        nextImg()
      });

      //モーダルの内容を更新
      refleshModal();
    }

    //指定した番号の画像を表示
    function refleshModal(){
      //elemの子要素（img, p）から値を取得する
      // var text = $(elem).eq(no).html();

      var imgurl = $(elem).eq(currentNo).find('img').attr('src');
      var imgtag = '<p class="l-picture l-photoCenter"><img class="thumbImg" src="' + imgurl +  '" alt="" title=""></p>';
      $(lightBoxInner).html(imgtag);
      $(lightBoxPage).html( adjustCurrentNo(currentNo) + '/' + itemCnt);
      //lightBgとlightBoxを表示する
      $(lightBg).stop().fadeIn('slow');
      $(lightBox).stop().hide().fadeIn('slow');
      $(lightBoxBtn).stop().fadeIn('slow');

      //wrapのスクロールバーを非表示にする
      $(wrap).css('overflow', 'hidden');
    }

    //戻るボタンクリック処理
    function prevImg(){
      if(currentNo === 0){
        currentNo = itemCnt - 1;
      }
      else{
        currentNo--;
      }
      refleshModal(currentNo);
    }

    //次へボタンクリック処理
    function nextImg(){
      if(currentNo === ( itemCnt - 1 ) ){
        currentNo = 0;
      }
      else{
        currentNo++;
      }
      refleshModal(currentNo);
    }

    //初期表示を1番目にカウントするよう調整
    function adjustCurrentNo(no){
      if(no === 0){
        return itemCnt;
      }
      return no;
    }

  })();


  /*==========================================
    .lightBoxItem
    lightBoxの設定（ディティール・カラバリ用）
  ==========================================*/
  ;(function(){

    //変数定義
    var elem = '.lightBoxItem';
    var lightBox = '.lightBox';
    var lightBg = '.is-lightBoxBg';
    var lightBoxInner = '.lightBoxInner';
    var lightBoxPage = '.lightBoxPage';
    var lightBoxBtn = '.lightBoxBtn';
    var lightBoxPrevBtn = '.lightBoxPrevBtn';
    var lightBoxNextBtn = '.lightBoxNextBtn';
    var lightBoxClick = '.is-lightBoxBg, .lightBoxBtn';
    var currentNo, items, itemCnt;

    if($(elem).length){
      //elemがクリックされたら
      $(elem).on('click', function(){
        items = $(this).parent().find('li');
        currentNo = $(items).index(this);
        itemCnt = items.length;
        openModal();
      });

      //lightBox表示中にlightBgがクリックされたら
      $(wrap).on('click', lightBoxClick, function(){
        //lightBgとlightBoxを削除する
        $(lightBg).removeClass('is-fadeAnimation').fadeOut('slow', function(){ $(this).remove(); });
        $(lightBox).fadeOut('slow', function(){ $(this).remove(); });
        $(lightBoxBtn).fadeOut('slow', function(){ $(this).remove(); });
        $(lightBoxPrevBtn).fadeOut('slow', function(){ $(this).remove(); });
        $(lightBoxNextBtn).fadeOut('slow', function(){ $(this).remove(); });
        //wrapのスクロールバーを表示する
        $(wrap).css('overflow', 'auto');
      });
    }

    //モーダルをオープン
    function openModal(){
      //wrapにlightBoxを追加する
      $(wrap).append('<div class="windowBg is-fadeAnimation is-lightBoxBg"></div>');
      $(wrap).append('<div class="lightBox"></div>');
      $(wrap).append('<p class="lightBoxBtn"></p>');
      $(lightBox).append('<div class="lightBoxInner"></div>');
      $(lightBox).append('<div class="lightBoxPage"></div>');
      $(wrap).append('<p class="lightBoxPrevBtn"></p>');
      $(wrap).append('<p class="lightBoxNextBtn"></p>');

      //戻るボタンクリック時
      $('.lightBoxPrevBtn').on('click', function(){
        prevImg()
      });

      //次へボタンクリック時
      $('.lightBoxNextBtn').on('click', function(){
        nextImg()
      });

      //モーダルの内容を更新
      refleshModal();
    }

    //指定した番号の画像を表示
    function refleshModal(){
      //elemの子要素（img, p）から値を取得する
      var text = $(items).eq(currentNo).html();

      //lightBoxに要素を追加する（他に追加する場合も同様）
      //elemの値を追加したlightBoxの要素に設定する
      $(lightBoxInner).html(text);
      $(lightBoxPage).html( (currentNo + 1) + '/' + itemCnt);
      //lightBgとlightBoxを表示する
      $(lightBg).stop().fadeIn('slow');
      $(lightBox).stop().hide().fadeIn('slow');
      $(lightBoxBtn).stop().fadeIn('slow');

      //wrapのスクロールバーを非表示にする
      $(wrap).css('overflow', 'hidden');
    }

    //戻るボタンクリック処理
    function prevImg(){
      if(currentNo === 0){
        currentNo = itemCnt - 1;
      }
      else{
        currentNo--;
      }
      refleshModal();
    }

    //次へボタンクリック処理
    function nextImg(){
      if(currentNo === ( itemCnt - 1 ) ){
        currentNo = 0;
      }
      else{
        currentNo++;
      }
      refleshModal();
    }

  })();

  /*==========================================
    .is-specTableWrap
    hover時のアクション table(スペック表)の着色を変更
  ==========================================*/
  ;(function(){
    if($('.is-specTableWrap').length){
      var currentClass = 'is-current';//current時のクラス名

      /*==========================================
        .is-specTableWrap  th hover時
      ==========================================*/
      $('.is-specTableWrap').find('th').hover(function(){
        var thisIndex = $(this).index(); //hoverのhtml上の番号

        //hoverしているthに対して、クラスを付与
        $(this).addClass(currentClass);

        //.is-specTableWrap tr全検索し、子要素のtdのthisIndex番目にクラスを付与
        $('.is-specTableWrap').find('tr').each(function(e){
          $($(this).find('td')[thisIndex]).addClass(currentClass);
        });

      /*==========================================
        .is-specTableWrap th hover解除直後
      ==========================================*/
      },function(){

        //.is-specTableWrap thに対してcurrentClass削除
        $('.is-specTableWrap').find('th').removeClass(currentClass);
        //.is-specTableWrap tdに対してcurrentClass削除
        $('.is-specTableWrap').find('td').removeClass(currentClass);
      });



      /*==========================================
        .is-specTableWrap td hover時
      ==========================================*/
      $('.is-specTableWrap').find('td').hover(function(){
        var thisIndex = $(this).index(); //hoverのhtml上の番号

        //.is-specTableWrap trに対して、クラスを付与
        $(this).parent('tr').addClass(currentClass);
        $($(this).parents('.is-specTableWrap').find('th')[thisIndex]).addClass(currentClass);
        //.is-specTableWrap tr全検索し、子要素のtdのthisIndex番目にクラスを付与
        $(this).parents('.is-specTableWrap').find('tr').each(function(e){
          $($(this).find('td')[thisIndex]).addClass(currentClass);
        });

      /*==========================================
        .is-specTableWrap td hover解除直後
      ==========================================*/
      },function(){
        //.is-specTableWrap tr th tdに対してcurrentClass削除
        $('.is-specTableWrap').find('tr').removeClass(currentClass);
        $('.is-specTableWrap').find('th').removeClass(currentClass);
        $('.is-specTableWrap').find('td').removeClass(currentClass);
      });
    }
    /*==========================================
      URLパラメーターがあるとき、スペック表(製品情報)のアクティブ表示する
    ==========================================*/

    /*==========================================
      1)スペック表のTable要素
      2)アクティブ時のクラス名
      3)URLパラメーター　〇〇〇=製品番号の、〇〇〇
      4)scrollのスピード

      new ProductCodeFun( 1, 2, 3, 4 );
    ==========================================*/
    new ProductCodeFun($(".specTableWrap"), 'is-currentCode',  "code", 1 );
  })();

  /*==========================================
    スライダーの処理 (全ての画像が読み込み終えてから実行)
  ==========================================*/

  //sliderがない場合もあるので、読み込み直後に削除
  if($('.is-slickFirst').length){
    $('.is-slickFirst').addClass('is-slickFirstActive');
  }
  /*==========================================
    サムネイルホバー時の画像切り替え
  ==========================================*/
  new ThumbSlideHover($('.l-sliderThumbList').children().find('a'));


  /*==========================================
    .l-sliderThumbParent（Swiper）スライダー
  ==========================================*/
  ;(function(){

    if( $('.l-sliderThumbParent').length ){
      /*==========================================
        Swiperの設定（SPのみ）
        speed 400
        loop 禁止
        ボタンの設定　.thumbButtonNext,.thumbButtonPrev
      ==========================================*/
      var sp_ThumbContainerOptions = {
        slidesPerView: 'auto',
        speed: 400,
        loop: false,
        nextButton: '.thumbButtonNext',
        prevButton: '.thumbButtonPrev'
      }

      ThumbContainerSwiper()
      $(window).on('resize',function(){
        ThumbContainerSwiper();
      });

      /*==========================================
        Swiperの設定、PCなし　SPのみ実行の処理
      ==========================================*/
      var LsliderThumbSwiper = undefined;
      function ThumbContainerSwiper() {
        var screenWidth = wW;
        //PCサイズ時、LsliderThumbSwiperが空ではない時
        if (screenWidth > spSize && LsliderThumbSwiper != undefined) {

          //.l-sliderThumbParent要素が複数ある場合
          if($.isArray(LsliderThumbSwiper)){
            _.each(LsliderThumbSwiper,function(elm,i){
              elm.destroy();
            });
          //.l-sliderThumbParent要素が1つのみの場合
          }else{
            LsliderThumbSwiper.destroy();
          }
          //Swiperの入力値とスタイルの削除
          $('.l-sliderThumbParent').find('.swiper-wrapper').removeAttr('style');
          LsliderThumbSwiper = undefined;
        //SPサイズ時で、LsliderThumbSwiperが空の時
        } else if(screenWidth <= spSize && LsliderThumbSwiper == undefined) {
          LsliderThumbSwiper = new Swiper(".l-sliderThumbParent .l-sliderThumbContainer", sp_ThumbContainerOptions);
        }else{

        }
      }
    }

  })();


  /*==========================================
    .l-sliderTvRankingParent（Swiper）スライダー
  ==========================================*/
  ;(function(){

    if( $('.l-sliderTvRankingParent').length ){
      /*==========================================
        Swiperの設定（SPのみ）
        speed 400
        loop 禁止
        ボタンの設定　.thumbButtonNext,.thumbButtonPrev
      ==========================================*/
      var tvRankingContainerOptions = {
        slidesPerView: 'auto',
        speed: 400,
        loop: false,
        nextButton: '.tvRankingButtonNext',
        prevButton: '.tvRankingButtonPrev'
      }

        new Swiper(".l-sliderTvRankingParent .l-sliderTvRankingContainer", tvRankingContainerOptions)

    }

  })();


  /*==========================================
    .l-sliderTvThumbParent（Swiper）スライダー
  ==========================================*/
  ;(function(){

    if( $('.l-sliderTvThumbParent').length ){
      /*==========================================
        Swiperの設定（SPのみ）
        speed 400
        loop 禁止
        ボタンの設定　.thumbButtonNext,.thumbButtonPrev
      ==========================================*/
      var tvThumbContainerOptions = {
        slidesPerView: 'auto',
        speed: 400,
        loop: false,
        nextButton: '.tvThumbButtonNext',
        prevButton: '.tvThumbButtonPrev'
      }

       new Swiper(".l-sliderTvThumbParent .l-sliderTvThumbContainer", tvThumbContainerOptions)

    }

  })();


  /*==========================================
    .l-sliderThumbParent（Swiper）スライダー
  ==========================================*/

  ;(function(){
    if( $('.l-sliderTvRelationParent').length ){
      /*==========================================
        Swiperの設定（SPのみ）
        speed 400
        loop 禁止
        ボタンの設定　.thumbButtonNext,.thumbButtonPrev
      ==========================================*/
      var sp_tvRelationContainerOptions = {
        slidesPerView: 'auto',
        speed: 400,
        loop: false,
        nextButton: '.tvRelationButtonNext',
        prevButton: '.tvRelationButtonPrev'
      }

      TvRelationContainerSwiper()
      $(window).on('resize',function(){
        TvRelationContainerSwiper();
      });

      /*==========================================
        Swiperの設定、PCなし　SPのみ実行の処理
      ==========================================*/
      var LsliderTvRelationSwiper = undefined;
      function TvRelationContainerSwiper() {
        var screenWidth = wW;
        //PCサイズ時、LsliderTvRelationSwiperが空ではない時
        if (screenWidth > spSize && LsliderTvRelationSwiper != undefined) {

          //.l-sliderThumbParent要素が複数ある場合
          if($.isArray(LsliderTvRelationSwiper)){
            _.each(LsliderTvRelationSwiper,function(elm,i){
              elm.destroy();
            });
          //.l-sliderThumbParent要素が1つのみの場合
          }else{
            LsliderTvRelationSwiper.destroy();
          }
          //Swiperの入力値とスタイルの削除
          $('.l-sliderTvRelationParent').find('.swiper-wrapper').removeAttr('style');
          LsliderTvRelationSwiper = undefined;
        //SPサイズ時で、LsliderTvRelationSwiperが空の時
        } else if(screenWidth <= spSize && LsliderTvRelationSwiper == undefined) {
          //（Swiper）スライダーの実行
          LsliderTvRelationSwiper = new Swiper(".l-sliderTvRelationParent .l-sliderTvRelationContainer", sp_tvRelationContainerOptions);
        }else{

        }
      }
    }
  })();


  /*==========================================
    .l-sliderParent（Swiper）スライダー
  ==========================================*/
  ;(function(){

    if( $('.l-sliderParent').length ){

      SliderParent();
      $(window).on('resize',function(){
        SliderParent();
      });

      /*==========================================
        スライダーの設定、PC(Slick)/SP(Swiper)実行の処理
      ==========================================*/
      var sliderContainerSwiper = '';
      var deviceFlag = '';
      var firstFlag = undefined;
      function SliderParent(){
        var screenWidth = wW;
        //1回目、Swiper読み込み時
        //PC時
        if (screenWidth > spSize && firstFlag == undefined) {
          sliderContainerSwiperPc();
          firstFlag = true;
          deviceFlag = 'pc';
        //SP時
         }else if(screenWidth <= spSize && firstFlag == undefined) {
            sliderContainerSwiperSp();
            firstFlag = true;
            deviceFlag = 'sp';
         }else{}



        /*==========================================
          PC(Slick)/SP(Swiper)の設定
          2回目以降のPC/SP切り替え時
        ==========================================*/
        //PCサイズ時、firstFlagがtrue deviceFlagがspの時
        if ( screenWidth > spSize && firstFlag == true && deviceFlag == 'sp' ) {
          //swiperをdestroy
          //.l-sliderContainer要素が複数ある場合
          if($.isArray(sliderContainerSwiper)){
            _.each(sliderContainerSwiper,function(elm,i){
              elm.destroy();
            });
          //.l-sliderContainer要素が1つのみの場合
          }else{
            sliderContainerSwiper.destroy();
          }
          //pc slick読み込み
          sliderContainerSwiperPc();
          //deviceFlagをpcに変更
          deviceFlag = 'pc';

        //SPサイズ時、firstFlagがtrue deviceFlagがpcの時
        } else if (screenWidth <= spSize && firstFlag == true && deviceFlag == 'pc') {
          //slickをdestroy
          $(".l-slider").slick('unslick');
          //sp swiper読み込み
          sliderContainerSwiperSp();
          //deviceFlagをspに変更
          deviceFlag = 'sp';
        }else{}
      }

      /*==========================================
      PC
      Slickの設定
      ==========================================*/
      function sliderContainerSwiperPc (){
        $(".l-slider").not('.slick-initialized').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true
        });
      }
      /*==========================================
      SP
      Swiperの設定
      ==========================================*/

      function sliderContainerSwiperSp (){
        sliderContainerSwiper = new Swiper (".l-sliderContainer", {
          loop: false,
          speed: 400,
          noSwiping: false,
          noSwipingClass: 'no_swipe',
          slidesPerView: 'auto',
          spaceBetween: 0,
          nextButton: '.sliderButtonNext',
          prevButton: '.sliderButtonPrev',
          pagination: '.swiper-pagination',
          paginationClickable: true
       });
       firstFlag = true;
      }
    }
  })();


  /*==========================================
    KVの黒・白色判定とslickの設定
    kvが2つ以上のときはslickを実行しましょう。
    1) header
    2) kvの要素
    3) kv内のブランドのロゴ
    4) 通常のロゴ・KV黒番のロゴを囲うspanタグのクラスを指定
    5) 追従するメニューボタン
    6) 黒いKVのエリア(html内のクラス名を指定)
    7) 黒いKV表示時にheaderに付与するクラス
    8) 黒いKV表示時、4)の個数が1個以上の企業ロゴは、8)のクラスを付与する。
    new TopKV(1,2,3,4,5,6,7,8);
  ==========================================*/
  ;(function(){
    if($('.is-kvSlider').length){
      var kvSlider = 0;
      var kvSliderChild = $('.is-kvSlider').children().length;
      if(kvSliderChild > 0){
       kvSlider = new TopKV($('.l-header'), $('.is-kvSlider'), $('.l-header .is-blandlogo'), '.is-Logo', $('.is-haderNavBtn span'),'is-kvblack','is-kvblackActive','is-logoActive');
      }
      if(kvSliderChild > 1){
        kvSlider.slickFun();
      }

    }
  })();

  /*==========================================
    .featuredSlider
    Slickの設定(.thumbnailInner)が追加nav
  ==========================================*/
  ;(function(){
  if($('.is-ftSlider').length){
    let variableWidth = true;
    // let ftSliderLen = $('.is-ftSliderItem').length;
    // if(ftSliderLen <= 3){
    //   variableWidth = false;
    // }
    const ftSliderS_OP = {
      autoplay : false,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      variableWidth: variableWidth,
      appendArrows: $('.is-ftSliderArrws'),
      responsive: [
        {
          breakpoint: 1064,
          settings: {
            slidesToShow: 2,
            variableWidth: true,
          }
        },
        {
          breakpoint: spSize,
          settings: {
            dots: true,
            slidesToShow: 1,
            variableWidth: true,
            appendDots: $('.is-ftSliderArrws'),
          }
        }
      ]  
    };
    // let shiftNum = Number($('.contentsInner').eq(0).css('margin-left').slice(0,-2)) + Number($('.contentsInner').eq(0).css('padding-left').slice(0,-2))
    // $('.is-ftSlider').css('transform','translateX('+shiftNum+'px)')
    /*==========================================
      .slickの読み込みとリセット処理
    ==========================================*/
    $('.is-ftSlider').slick(ftSliderS_OP);
  }
})();

  /*==========================================
    .productSlider
    Slickの設定(.thumbnailInner)が追加nav
  ==========================================*/
  if($('.productSlider').length){
    var productSliderS_OP = {
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      breakpoints: {
        pcSize: {
          fade: true,
          asNavFor: '.thumbnailInner',
        },
        spSize: {
          fade: false
        }
      }
    };

    /*==========================================
      .slickの読み込みとリセット処理
    ==========================================*/
    var productSliderS = $('.productSlider').slick( productSliderS_OP );
    productSliderS.slick( 'setPosition', productSliderS_OP );

    /*==========================================
    .thumbnailInner
    Slickの設定(.productSlider)読み込みと連動
    ==========================================*/
    if( $('.thumbnailInner').length ){
      var thumbnailInnerS_OP = {
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.productSlider',
        dots: false,
        vertical: true,
        focusOnSelect: true,
        centerMode: true,
        arrow: true
      };
      /*==========================================
        .slickの読み込みとリセット処理
      ==========================================*/
      var thumbnailInnerS = $(".l-thumbnailInner img"),  thumbnailInnerS = $('.thumbnailInner').slick( thumbnailInnerS_OP );
      thumbnailInnerS.slick( 'setPosition', thumbnailInnerS_OP );
    }
  }

  /*==========================================
    .thumbAddSlider
    Slickの設定
  ==========================================*/
  if($('.thumbAddSlider').length){


    /*==========================================
      Slickのページャーの位置調整
    ==========================================*/
    var maxheight = 0;
    /*==========================================
      注意点
      slickのinitは対象の〇〇.slick({});より前に記述すること
    ==========================================*/
    $('.thumbAddSlider').on('init',function(){
        $('.thumbAddSlider').each(function(){
          $(this).find('.slick-track').children().css({
            'min-height': ''
          });
          $(this).find('.slick-track').children().each(function(e){
            if(e == 0){
              maxheight = $(this).innerHeight();
            }else{
              maxheight = Math.max($(this).innerHeight(), maxheight);
            }
          });
          $(this).find('.slick-track').children().css({
            'min-height': maxheight
          });
        });
    });

    $('.thumbAddSlider').slick({
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrow: true,
      infinite: false,
      responsive: [
          {
            breakpoint: spSize,
            settings: {
              slidesToShow: 2
            }
          }
      ]
    });
  }


  /*==========================================
    .is-photoSideSlider
    Slickの設定　サムネイルの個数が1つ以上の時、slickにする
  ==========================================*/
  if($('.is-photoSideSlider').length){
    $('.is-photoSideSlider').each(function(){
      if( $(this).children().length > 1 ) {


        $(this).slick({
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          focusOnSelect: true,
          arrow: true,
          responsive: [
              {
                breakpoint: spSize,
                settings: {
                  dots: true
                }
              }
          ]
        });
      }
    });


  }



  /*==========================================
    .kvKeywordWrap
    検索クリック時
  ==========================================*/
  ;(function(){
    var openClass = "is-open";
    var kvKeywordBGClass = "is-kvKeywordBG";

    if($('.kvKeywordWrap .kvKeywordInput').length){
      $('.kvKeywordWrap .kvKeywordInput').on('click',function(){
        //初期状態は、背景削除
        if($( '.' + kvKeywordBGClass ).length){
          $( '.' + kvKeywordBGClass ).remove();
        }
        //PC時
        if(wW > spSize) {
          $('.l-header').append('<div class="is-bgHeader '+ kvKeywordBGClass +'"></div>');

        //SP時
        }else{
          //背景の要素がない場合は追加
          $(wrap).append('<div class="windowBg '+ kvKeywordBGClass +'"></div>');

        }

        //開閉用のクラス付与
        $(this).parents('.kvKeywordWrap').addClass( openClass );
        //KVのスライダーがある場合は停止
        if($('.is-kvSlider').length){
          $('.is-kvSlider').slick('slickPause');
        }
        $('.searchIconList').show();

      });

      $(document).on('click',   function(e) {
        if (!$(e.target).closest('.kvKeywordWrap .kvKeywordInput').length) {
          //キーワードに開いている時のクラスが付与されている時
          if($('.kvKeywordWrap').hasClass(openClass)){
            //KVのスライダーがある場合は再開
            if($('.is-kvSlider').length){
              $('.is-kvSlider').slick('slickPlay');
            }
            $('.searchIconList').hide();


            //背景は削除
            if($( '.' + kvKeywordBGClass ).length){
              $( '.' + kvKeywordBGClass ).remove();
            }

            $('.kvKeywordWrap').removeClass( openClass );

          }

        }
      });
    }

  })();


  /*==========================================
    commonScript.jsで検索を実行したときに実行
    js_ThumbSlideHoverFirst   検索（初期読み込み）
    js_ThumbSlideHoverActive  検索（2回目以降）
    js_FurtherBtnActive       tvの検索リスト一覧
  ==========================================*/
  // プラグインの定義
  (function() {
    var searchLoad;　　　　　　　　　　// 任意な名前
    searchLoad = {　　　　　　　　　　 // オブジェクトの定義
      js_ThumbSlideHoverFirst : function() { // オブジェクトなインナー関数
        // サムネイルホバー時の画像切り替え
        new ThumbSlideHover($("#furtherBtnArea02 .is-firstActive a"));
        $($("#furtherBtnArea02").find(".is-active")).each(function(e){
          if(!$(this).hasClass("is-show")){
            $(this).addClass("is-show");
          }
        });
        $("#furtherBtnArea02").find(".is-active").addClass("is-show");
      },
      js_ThumbSlideHoverActive : function() { // オブジェクトなインナー関数
        // サムネイルホバー時の画像切り替え
        new ThumbSlideHover($("#furtherBtnArea02 li").not(".is-show").not(".is-firstActive").find("a"));
        $($("#furtherBtnArea02").find(".is-active")).each(function(e){
          if(!$(this).hasClass("is-show")){
            $(this).addClass("is-show");
          }
        });
      },
      js_FurtherBtnActive : function() { // オブジェクトなインナー関数
        // サムネイルホバー時の画像切り替え
        new FurtherBtn($('.is-tvdetailSearchBtn a'), 10, 10, false);
      }
    };
    window.bundle = searchLoad;  // windowオブジェクトに"searchLoad"名としてバインディング

  })();
  /*==========================================
    navBtnの線ひとつけす
  ==========================================*/
  $('.haderNavBtn').find('span:nth-child(3)').remove();
  /*==========================================
    header追従
  ==========================================*/
  let _window = $(window),
      _header = $('.headerTitleArea'),
      heroBottom;

  toggleFixedHeader();
  _window.on('scroll',function(){
    toggleFixedHeader();
  });

  function toggleFixedHeader(){
    // heroBottom = _header.css('padding-top').slice(0,-2);
    heroBottom = _header.outerHeight();
    if(_window.scrollTop() > heroBottom){
      _header.addClass('is-fixedHeader'); 
    }
    else{
      _header.removeClass('is-fixedHeader');  
    }
  }

});



/*==========================================
    Youtube_defer コンストラクタ
==========================================*/
function Youtube_defer($target){
  this.$target = $target;
  this.event();
}

Youtube_defer.prototype.event = function(){
  var self = this;
  $(function(){
    $(self.$target).contents().find('head').append('<link rel="stylesheet" href="/common/css/lazyload_load.css" type="text/css" />');
  });

  var targetup = function(){
    $(self.$target).each(function(e){
      if($(this).attr('data-src')) {
        $(this).attr('src', $(this).attr('data-src'));
      }
    });
  }
  setTimeout(targetup, 10000);


}

/*==========================================
    ページトップへ戻る コンストラクタ
==========================================*/
function PageTop( $pageTopLink, $header, $contentsInner, $footer, fadeClass, fixClass ){
  if($pageTopLink == undefined ){
    return false;
  }
  this.$pageTopLink = $pageTopLink;
  this.$pageTopLinkChildren = $pageTopLink.children();
  this.$header = $header;
  this.$contentsInner = $contentsInner;
  this.$footer = $footer;
  this.fadeClass = fadeClass;
  this.fixClass = fixClass;

  this.pageTopLinkChildrenHight = 0;
  this.footerTop = 0;
  this.event();
}

/*==========================================
  ページトップへ戻る 挙動
==========================================*/
PageTop.prototype.init = function(){
  var self = this;
  var footerTop = $(self.$footer).offset().top;
  //headerの一番下の位置を測定
  self.headerBottom = self.$header.offset().top + self.$header.innerHeight();
  //footerの一番上の位置を測定
  //pageTopLinkのサイズと一番下の位置を測定
  self.pageTopLinkChildrenHight = self.$pageTopLinkChildren.innerHeight();

  //pageTopLinkの表示・非表示(headerの表示ラインを超えたか測定)
  if( $(window).scrollTop() <= self.headerBottom ){
    if( self.$pageTopLink.hasClass(self.fadeClass) ){
      self.$pageTopLink.fadeOut(400);
      self.$pageTopLink.removeClass(self.fadeClass);
    }
  }else{
    if(!self.$pageTopLink.hasClass(self.fadeClass)){
      self.$pageTopLink.fadeIn(400);
      self.$pageTopLink.addClass(self.fadeClass);
    }
  }


  //footerの位置よりも上の場合
  if( $(window).scrollTop()  + $(window).innerHeight() - self.pageTopLinkChildrenHight <= footerTop ){

    //pageTopLinkのleftの位置(コンテンツ幅を超えた際)調整
    if(self.$contentsInner.length){
      var contentsLeft = $(self.$contentsInner).offset().left; //コンテンツの位置
      var contentsW =$(self.$contentsInner).innerWidth(); //コンテンツの横幅サイズ
      if( contentsLeft + contentsW + self.$pageTopLinkChildren.innerWidth() * 1.5 < wWSite ) {
        self.$pageTopLink.css({'left': contentsLeft + contentsW + self.$pageTopLinkChildren.innerWidth() / 2, 'right': 'auto'});
      }else{
        self.$pageTopLink.css({'left': '' , 'right': ''});
      }
    }


    if(self.$pageTopLink.hasClass(self.fixClass)){
      self.$pageTopLink.removeClass(self.fixClass);
    }
  }else{

    //pageTopLinkのleftの位置(コンテンツ幅を超えた際)調整
    if(self.$contentsInner.length){
      var contentsLeft = $(self.$contentsInner).offset().left; //コンテンツの位置
      var contentsW =$(self.$contentsInner).innerWidth(); //コンテンツの横幅サイズ
      if( contentsLeft + contentsW + self.$pageTopLinkChildren.innerWidth() * 1.5 < wWSite ) {
        self.$pageTopLinkChildren.css({'left': contentsLeft + contentsW + self.$pageTopLinkChildren.innerWidth() / 2, 'right': 'auto'});
        self.$pageTopLink.css({'left': '' , 'right': ''});
      }else{
        self.$pageTopLinkChildren.css({'left': '' , 'right': ''});
        self.$pageTopLink.css({'left': '' , 'right': ''});
      }
    }

    if(!self.$pageTopLink.hasClass(self.fixClass)){
      self.$pageTopLink.addClass(self.fixClass);
    }
  }
}

/*==========================================
  ページトップへ戻る イベント
==========================================*/
PageTop.prototype.event = function(){
  var self = this;
  self.init();
  $(window).on("resize scroll", function(){
    self.init();
  });
}



/*==========================================
  AccordionFun コンストラクタ
==========================================*/
function AccordionFun( $target, classOpen, accordionSpeed){
  var self = this;
  this.$target = $target;
  this.classOpen = classOpen;
  this.accordionSpeed = accordionSpeed;
  this.clickViewAction();
}

/*==========================================
  AccordionFun clickViewAction クリック時の処理
==========================================*/
AccordionFun.prototype.clickViewAction = function(){
  var self = this;
  $(self.$target).on('click',function(e){
    //.is-accordionBtnTargetのherfの要素を保存
    var $targetHref = $($(this).attr('href'));
    //$targetが非表示時
    if(!$targetHref.is(":visible")){
      //クリックされた要素の親要素にclassOpenを付与
      $(this).parent().addClass(self.classOpen);
      //クリックされた要素を表示
      $targetHref.slideDown(self.accordionSpeed);

    //$targetが表示時
    }else{
      //クリックされた要素の親要素にclassOpenを削除
      $(this).parent().removeClass(self.classOpen);
      //クリックされた要素を非表示
      $targetHref.slideUp(self.accordionSpeed);
    }
    e.preventDefault();
  });
}


/*==========================================
  FadeFun コンストラクタ
==========================================*/
function FadeFun($fadeItem, currentClass, marginV, firstSetLoadTime){
  var self = this;
  this.$fadeItem = $fadeItem; //fadeする要素
  this.currentClass = currentClass; //fade時に付与するクラス

  //marginVに値がないとき
  if( marginV == undefined ){
    this.marginV = 0;
  //marginVに値がある時
  }else{
    this.marginV = marginV;
  }

  //firstSetLoadTimeに値がないとき
  if( firstSetLoadTime == undefined ){
    this.firstSetLoadTime = 0;
  //marginVに値がある時
  }else{
    this.firstSetLoadTime = firstSetLoadTime;
  }


  this.fadeItemTop = [];


  this.paramFun();
}

/*==========================================
  FadeFun コンストラクタ　イベントの設定
==========================================*/
FadeFun.prototype.paramFun = function(){
  var self = this;

  setTimeout(function(){
    //fadeITemの位置を取得
    self.$fadeItem.each(function(e){
      self.fadeItemTop[e] = $(this).offset().top;
    });
    //eventProgramFun()実行
    self.eventProgramFun();

  }, self.firstSetLoadTime );


  $(window).on("resize scroll", function(){
    //fadeItemの数を検索
    self.$fadeItem.each(function(e){
      //該当のfadeItemにクラスcurrentClassが当てはまらないとき
      if(!$(this).hasClass(self.currentClass)){
        self.fadeItemTop[e] = $(this).offset().top;

        //eventProgramFun()実行
        self.eventProgramFun();
      }
    });

  });
}
/*==========================================
  FadeFun コンストラクタ　fadeの処理に関して
==========================================*/
FadeFun.prototype.eventProgramFun = function(){
  var self = this;
  var scrollTopV = $(window).scrollTop();

  //サイト一番下
  if($('html,body').innerHeight() -1 <= scrollTopV + $(window).innerHeight()){
    $(self.$fadeItem).addClass(self.currentClass);

  //サイト一番下より上
  }else{
    //fadeItemTopの配列の値
    $.each(self.fadeItemTop, function(i,value){

      //スクロールの値を取得し、画面上に要素が現れた位置化からmarginVずらした位置を通過した時点で
      if( scrollTopV >= value - $(window).innerHeight() + self.marginV ){
        //該当のfadeItemに対して、currentClassを付与
        $(self.$fadeItem[i]).addClass(self.currentClass);
      }
    });
  }


}


/*==========================================
  FadeFunLeft コンストラクタ
==========================================*/
function FadeFunLeft( $fadeItem, currentNameItem, currentClass, marginV, firstSetLoadTime, setLoadTime ){
  var self = this;
  this.$fadeItem = $fadeItem;　//fadeItemを含んだ親要素　通過地点の測定に利用
  this.currentNameItem = currentNameItem;　//currentクラスをつける対象
  this.currentClass = currentClass;//current時のクラスネーム

  //marginVに値がないとき
  if( marginV == undefined ){
    this.marginV = 0;
  //marginVに値があるとき
  }else{
    this.marginV = marginV;
  }


  //firstSetLoadTimeに値がないとき
  if( setLoadTime == undefined ){
    this.firstSetLoadTime = 0;
  //firstSetLoadTimeに値があるとき
  }else{
    this.firstSetLoadTime = firstSetLoadTime;
  }


  //setLoadTimeに値がないとき
  if( setLoadTime == undefined ){
    this.setLoadTime = 0;
  //setLoadTimeに値があるとき
  }else{
    this.setLoadTime = setLoadTime;
  }


  this.fadeItemTop = [];

  this.paramFun();
}


/*==========================================
  FadeFunLeft コンストラクタ　イベントの設定
==========================================*/
FadeFunLeft.prototype.paramFun = function(){
  var self = this;

  //$fadeItemの数だけ
  setTimeout(function(){
    self.$fadeItem.each(function(e){
      //$fadeItemの位置を取得
      self.fadeItemTop[e] = $(this).offset().top;
    });
    //eventProgramFun()実行
    self.eventProgramFun();
  },self.firstSetLoadTime);

  $(window).on("resize scroll", function(){
    //fadeItemの配列の値
    self.$fadeItem.each(function(e){
      //該当のfadeItemにクラスcurrentClassが当てはまらないとき
      if(!$(this).hasClass(self.currentClass)){
        self.fadeItemTop[e] = $(this).offset().top;

        setTimeout(function(){
          //eventProgramFun()実行
          self.eventProgramFun();
        },self.firstSetLoadTime);
      }
    });

  });
}
/*==========================================
  FadeFunLeft コンストラクタ　fadeの処理に関して
==========================================*/
FadeFunLeft.prototype.eventProgramFun = function(){
  var self = this;
  var scrollTopV = $(window).scrollTop();
  //サイト一番下
  if( $('html,body').innerHeight() -1 <= scrollTopV + $(window).height() ){
    $(self.$fadeItem).find(self.currentNameItem).addClass(self.currentClass);
  //サイト一番下より上
  }else{
    //fadeItemTopの配列の値
    $.each(self.fadeItemTop, function(i,value){
      //スクロールの値を取得し、画面上に要素が現れた位置化からmarginVずらした位置を通過した時点で
      if( scrollTopV >= value - $(window).height() + self.marginV ){
        $($(self.$fadeItem[i]).find(self.currentNameItem)).each(function(e){
          var $this = $(this);
          setTimeout(function(){
          //～ここに処理を記載～
          if(!$this.hasClass(self.currentClass)){
            $this.addClass(self.currentClass);
          }

        }, e * self.setLoadTime);
        });
      }
    });

  }

}





/*==========================================
  AccordionBtnTargetFun コンストラクタ
==========================================*/
function AccordionBtnTargetFun( $target, nextTarget, classOpen, accordionSpeed ){
  this.$target = $target;
  this.$targetNext = $($target).parent().next(nextTarget);
  this.classOpen = 'is-open';
  this.accordionSpeed = 400;
  this.clickViewAction();
}

/*==========================================
  AccordionBtnTargetFun clickViewAction クリック時の処理
==========================================*/
AccordionBtnTargetFun.prototype.clickViewAction = function(){
  var self = this;
  $(self.$target).on('click',function(e){
    //$targetが非表示時
    if(!$($(this).parent().next(self.nextTarget)).is(":visible")){
      //クリックされた要素の親要素にclassOpenを付与
      $(this).parent().addClass(self.classOpen);
      //クリックされた要素を表示
      $($(this).parent().next(self.nextTarget)).slideDown(self.accordionSpeed);
    //$targetが表示時
    }else{
      //クリックされた要素の親要素にclassOpenを削除
      $(this).parent().removeClass(self.classOpen);
      //クリックされた要素を非表示
      $($(this).parent().next(self.nextTarget)).slideUp(self.accordionSpeed);
    }
    e.preventDefault();
  });
}



/*==========================================
 MoreBtn （クラスを継承）
==========================================*/
//コンストラクタ
function MoreBtn($thisBtn, firstView){
  var self = this;
  this.$thisBtn = $thisBtn; //MoreBtnの要素
  this.btntext = $thisBtn.text(); //テキスト
  this.$thisBtnParent = this.$thisBtn.parent(); //MoreBtnの親要素
  this.$target = $($thisBtn.attr('href')); //MoreBtnのhref値(開閉するターゲットを取得)
  this.$targetChildren = $(this.$target).children();//href値(開閉するターゲット)の子要素
  this.firstView = firstView; //クリック前に表示している件数
  this.activeClass = 'is-active'; //Btn active時のクラス名
  this.openClass = 'is-open'; //target open時のクラス名
  this.firstViewAction(); //firstViewActionの実行
  this.actionFun(); //actionFunの実行

  //targetの子要素の数が、firstViewの数以下か
  if( self.$targetChildren.length <= self.firstView ){
    if(self.$thisBtnParent.hasClass(self.activeClass)){
      self.$thisBtnParent.removeClass(self.activeClass);
    }
  //targetの子要素の数が、firstViewの数を超える時
  }else{
    self.$thisBtnParent.addClass(self.activeClass);
  }
}

/*==========================================
 MoreBtn （firstViewAction）初期設定
==========================================*/
MoreBtn.prototype.firstViewAction = function(){
  var self = this;
  //ターゲットの子要素の数だけ
  self.$targetChildren.each(function(i){
    //クリック前に表示している件数までカウント
    if( i < self.firstView ) {
      //該当の$targetChildren表示
      $(this).show();
    //クリック前に表示している件数を超えるカウント
    }else{
      //該当の$targetChildren非表示
      $(this).hide();
    }
  });
}

/*==========================================
 MoreBtn （actionFun）クリック時の実行
==========================================*/
MoreBtn.prototype.actionFun = function(){
  var self = this;
  //$thisBtnをクリックしたとき
  $(self.$thisBtn).on('click',function(e){
    //$thisBtnの親要素にopenClassが付与されていないとき
    if(!self.$thisBtn.parent().hasClass(self.openClass)){
      //ターゲットの子要素を表示
      self.$targetChildren.show();
      //$thisBtnのテキストを「閉じる」
      self.$thisBtn.text('閉じる');
      //$thisBtnの親要素にopenClassを付与
      self.$thisBtn.parent().addClass(self.openClass);
    //$thisBtnの親要素にopenClassが付与されているとき
    }else{

      self.$thisBtn.text(self.btntext);
      //初期設定に戻す
      self.firstViewAction();
      //$thisBtnの親要素にopenClassを削除
      self.$thisBtn.parent().removeClass(self.openClass);
    }
      e.preventDefault();
  });
}



/*==========================================
 MoreBtn （クラスを継承）
==========================================*/
//コンストラクタ
function MoreBtnSlide($thisBtn, firstView, speed){
  var self = this;
  this.$thisBtn = $thisBtn; //MoreBtnの要素
  this.btntext = $thisBtn.text(); //ボタンテキスト
  this.$thisBtnParent = this.$thisBtn.parent(); //MoreBtnの親要素
  this.$target = $($thisBtn.attr('href')); //MoreBtnのhref値(開閉するターゲットを取得)
  this.$targetChildren = $(this.$target).children();//href値(開閉するターゲット)の子要素
  this.firstView = firstView; //クリック前に表示している件数
  this.speed = speed;
  this.firstActive = 'is-firstActive';
  this.activeClass = 'is-active'; //Btn active時のクラス名
  this.openClass = 'is-open'; //target open時のクラス名
  this.firstViewAction(); //firstViewActionの実行
  this.actionFun(); //actionFunの実行

  //targetの子要素の数が、firstViewの数以下か
  if( self.$targetChildren.length <= self.firstView ){
    if(self.$thisBtnParent.hasClass(self.activeClass)){
      self.$thisBtnParent.removeClass(self.activeClass);
    }
  //targetの子要素の数が、firstViewの数を超える時
  }else{
    self.$thisBtnParent.addClass(self.activeClass);
  }
}

/*==========================================
 MoreBtnSlide （firstViewAction）初期設定
==========================================*/
MoreBtnSlide.prototype.firstViewAction = function(){
  var self = this;
  //ターゲットの子要素の数だけ
  self.$targetChildren.each(function(i){
    //クリック前に表示している件数までカウント
    if( i < self.firstView ) {
      //該当の$targetChildren表示
      $(this).show().addClass(self.firstActive).addClass(self.activeClass);
    //クリック前に表示している件数を超えるカウント
    }else{
      //該当の$targetChildren非表示
      $(this).hide();
    }
  });
}

/*==========================================
 MoreBtnSlide （firstViewAction）初期設定
==========================================*/
MoreBtnSlide.prototype.secoudViewAction = function(){
  var self = this;
  //ターゲットの子要素の数だけ
  self.$targetChildren.each(function(i){
    //クリック前に表示している件数までカウント
    if( i < self.firstView ) {
      //該当の$targetChildren表示
      $(this).addClass(self.activeClass).slideDown(self.speed);
    //クリック前に表示している件数を超えるカウント
    }else{
      //該当の$targetChildren非表示
      $(this).removeClass(self.activeClass).slideUp(self.speed);
    }
  });
}
/*==========================================
 MoreBtnSlide （actionFun）クリック時の実行
==========================================*/
MoreBtnSlide.prototype.actionFun = function(){
  var self = this;
  //$thisBtnをクリックしたとき
  $(self.$thisBtn).on('click',function(e){
    //$thisBtnの親要素にopenClassが付与されていないとき
    if(!self.$thisBtn.parent().hasClass(self.openClass)){
      //ターゲットの子要素を表示
      self.$targetChildren.addClass(self.activeClass).slideDown(self.speed);
      //$thisBtnのテキストを「閉じる」
      self.$thisBtn.text('閉じる');
      //$thisBtnの親要素にopenClassを付与
      self.$thisBtn.parent().addClass(self.openClass);
    //$thisBtnの親要素にopenClassが付与されているとき
    }else{

      //$thisBtnのテキストを「もっと見る」
      self.$thisBtn.text(self.btntext);
      //初期設定に戻す
      self.secoudViewAction();
      //$thisBtnの親要素にopenClassを削除
      self.$thisBtn.parent().removeClass(self.openClass);
    }
      e.preventDefault();
  });
}


/*==========================================
 FurtherBtn （クラスを継承）
==========================================*/
//コンストラクタ
function FurtherBtn($thisBtn, firstView, count, spBtnNo){
  this.$thisBtn = $thisBtn;
  this.$thisBtnParent = this.$thisBtn.parent();
  this.$target = $($thisBtn.attr('href'));
  this.$targetChildren = $(this.$target).children();
  this.firstView = firstView;
  this.activeClass = 'is-active';//active時のクラス
  this.firstActiveClass = 'is-firstActive';//最初の表示の時クラス
  this.count = count;
  this.spBtnNo = spBtnNo;

  if( wW > spSize ){
    this.deviceFlag = "pc";
  }else{
    this.deviceFlag = "sp";
  }
  this.firstViewAction();
  this.actionFun();
  //ボタンの非表示
  if( this.$targetChildren.length <= this.firstView ){
    this.$thisBtnParent.hide();
  }else{
    this.$thisBtnParent.show();
  }
}



/*==========================================
 FurtherBtn （firstViewAction）初期設定
==========================================*/
FurtherBtn.prototype.firstViewAction = function(){
  var self = this;
  //$targetChildrenの数より初期設定の数が以上の時
  if( self.$targetChildren.length <= self.firstView　){
    //$thisBtnを非表示
    self.$thisBtn.hide(1);
  }else{
    //$thisBtnを表示
    self.$thisBtn.show(1);
  }

  //ターゲットの子要素の数だけ
  self.$targetChildren.each(function(i){
    //クリック前に表示している件数までカウント
    if( i < self.firstView ) {
      //ターゲットの子要素に対して、クラスis-activeとfirstActiveClassを付与
      $(this).addClass(self.activeClass).addClass(self.firstActiveClass);
    //クリック前に表示している件数を超えるカウント
    }
  });

  $(window).on('resize',function(){
    //pcサイズで、deviceFlagの値がspの時
    if( wW > spSize && self.deviceFlag == 'sp' ){
      //ターゲットの子要素の数が最初の表示個数よりも未満のとき
      if( self.$targetChildren.length < self.firstView　){
        //$thisBtn非表示
        self.$thisBtn.hide(1);
      }else{
        //$thisBtn表示
        self.$thisBtn.show(1);
      }

      //ターゲットの子要素の数だけ
      self.$targetChildren.each(function(i){
        //クリック前に表示している件数までカウント
        if( i < self.firstView ) {
          $(this).addClass(self.activeClass);
        //クリック前に表示している件数を超えるカウント
        }else{

          $(this).removeClass(self.activeClass);
        }
      });
      self.deviceFlag = 'pc';

      //spサイズで、deviceFlagの値がpcの時
    } else if( wW <= spSize && self.deviceFlag == 'pc' ){

      if( self.spBtnNo == true ){
        self.$thisBtn.hide(1);
        self.$targetChildren.removeClass(self.activeClass);
      }
      self.deviceFlag = 'sp';
    }else{}


  });

}

/*==========================================
 FurtherBtn （clickViewAction）クリック時に実行する処理
==========================================*/
FurtherBtn.prototype.clickViewAction = function(){
  var self = this;
  var beforeActiveLength = $(self.$target).children( '.'+ self.activeClass ).length;//ターゲットの子要素でactiveClass付与の件数
  var ActiveLength = beforeActiveLength + self.count; //activeにする件数をカウント
  //$targetの子要素の個数がactiveにする件数以下の時
  if( self.$targetChildren.length <= ActiveLength　){
    //thisBtn非表示
    self.$thisBtn.hide(1);
  }
  //targetの子要素の数だけ
  self.$targetChildren.each(function(i){
    //ActiveLength未満の時
    if( i < ActiveLength ) {
      //activeClassが付与されていない要素のみ
      if(!$(this).hasClass(self.activeClass)){

        //該当のtargetの子要素にactiveClass付与
        $(this).addClass(self.activeClass);
        //該当のtargetの子要素を表示
      }
    }
  });

}
/*==========================================
 FurtherBtn（actionFun）クリック時の実行
==========================================*/
FurtherBtn.prototype.actionFun = function(){
  var self = this;
  $(self.$thisBtn).on('click',function(e){
    self.clickViewAction();
    e.preventDefault();
  });
}

/*==========================================
  TabData
==========================================*/
function TabData($tabLink, $target,speed){
  var self = this;
  this.$tabLink = $tabLink;
  this.$target = $target;
  this.$targetParent = $target.parent();
  this.$targetParentId = '#' + $(this.$targetParent).attr('id');
  this.$targetMoreBtn = 0;
  $('a').each(function(e){
    if($(this).attr('href') == self.$targetParentId ){
      self.$targetMoreBtn = $(this);
    }
  });

  this.activeClass = 'is-active';
  this.speed = speed;
  this.thisHref,
  this.dataType,
  this.dataValue = 0;
  this.clickAction();
}

TabData.prototype.clickAction = function(){
  var self = this;
  $(self.$tabLink).on('click',function(e){
    $(self.$targetMoreBtn).hide();
    self.thisHref = $(this).attr('href').split('_')[0];
    self.dataType = 'data-' + self.thisHref.replace('#','');
    self.dataValue = $(this).attr('href').split('_')[1];
    self.$target.each(function(e){
      if( $(this).attr(self.dataType).indexOf(self.dataValue) != -1 ){
        $(this).addClass(self.activeClass).fadeIn(self.speed);
      }else {
        $(this).removeClass(self.activeClass).fadeOut(self.speed);
      }
    });

    e.preventDefault();
  });
}




/*==========================================
 ImgLoad （クラスを継承）
==========================================*/
//コンストラクタ
function ImgLoad($targetImgs,fun){
  this.$targetImgs = $targetImgs;
  this.loadImgAll = $targetImgs.length;  //img要素の全部の数
  this.loadImgCnt = 0; //読み込みが完了した数
  this.fun = fun;
  this.start();
}

ImgLoad.prototype.start = function(){
  var self = this;
  for(var i = 0; i < self.loadImgAll; i++){
    var tmpimg = $('<img>');
    tmpimg.on('load error', function(){
      self.loadImgCnt++;
      if(self.loadImgCnt == self.loadImgAll){
        self.fun;
      }
    });
    tmpimg.attr('src', $(self.$targetImgs[i]).attr('src'));
  }
}



/*==========================================
  ProductCodeFun コンストラクタ
==========================================*/
function ProductCodeFun($productCodeTable, currentClass, paraName, speed ){
  var self = this;
  this.$productCodeTable = $productCodeTable; //fadeする要素
  this.currentClass = currentClass; //fade時に付与するクラス
  this.paraName = paraName;
  this.speed = speed;

  paramFlagF();
  this.locationflag = locationflag;
  this.locationflag_arg01 = locationflag[0];
  this.locationflag_pair01 = locationflag[1];


  this.paramFun();
}

ProductCodeFun.prototype.init = function(){
  var self = this;
  var HitFlag = false;
  for (var key in self.locationflag_arg01) {
    if(key == self.paraName){


      $(self.$productCodeTable.find("td")).each(function(e){
        if( $(this).find('.newIcon').length ){
          if( self.locationflag_arg01[key] !== "" && $(this).find('.newIcon').text().indexOf(self.locationflag_arg01[key]) !== -1 ){
            $(this).parents("tr").addClass(self.currentClass);
            HitFlag = true;
          }
        }else{
          if( self.locationflag_arg01[key] !== "" && $(this).text().indexOf(self.locationflag_arg01[key]) !== -1 ){
            $(this).parents("tr").addClass(self.currentClass);
            HitFlag = true;
          }
        }

      });

    }
  }

  //テーブルとparaNameが一致したとき
  if( HitFlag == true ){
    var href= $(this).attr('href');
    var target = self.$productCodeTable;
    var position = target.offset().top;
    $('body,html').animate({scrollTop:position}, self.speed, 'swing');
  }
}

ProductCodeFun.prototype.paramFun = function(){
  var self = this;
  self.init();
}

/*==========================================
サムネイルホバー時の画像切り替え
==========================================*/
function ThumbSlideHover($thumb) {
 this.$thumbContainer = $thumb;
 this.FADE_SPEED = 500;  //フェードスピード
 this.DELAY_TIME = 1500; //写真切り替えタイミング
 this.interval;          //インターバル用
 this.enterflg = false;  //マウスエンターフラグ（初回エンターのみ動作させるため必要）
 this.init();
}

ThumbSlideHover.prototype.init = function () {
 var self = this;

 //a要素ホバー・アウト時
 self.$thumbContainer.on({
     'mouseenter': function () {
         self.setMouseEnter($(this));
     },
     'mouseleave': function () {
         self.setMouseLeave($(this));
     }
 });
}

ThumbSlideHover.prototype.setMouseEnter = function ($elm) {
 var self = this;
 var $imgs;

 $imgs = $elm.find('.thumbImg');

 //サムネイルが1つの場合は無視
 if ($imgs.length <= 1) { return };

 //マウスエンターに切り替わったタイミングに実行
 if (self.enterflg === false) {

   clearInterval(self.interval);

   //すべてのサムネイルのopacityを0にリセット
   $imgs.css({ 'opacity': '0' });

   //1番目のサムネイルを表示
   $imgs.eq(0).css({ 'opacity': '1' });

   //インターバルで繰り返し
   self.interval = setInterval(ImgFade, self.DELAY_TIME);
   setTimeout(ImgFade, 0); //最初だけすぐに実行

   self.enterflg = true;
 }

 //画像のクロスフェードアニメーション
 function ImgFade() {
  $elm.find('.thumbImg').eq(0).animate({ opacity: '0' }, self.FADE_SPEED).next('img').animate({ opacity: '1' }, self.FADE_SPEED).end().appendTo($elm.find('.l-picture'));
 }
}

ThumbSlideHover.prototype.setMouseLeave = function ($elm) {
 var self = this;
 var $imgs;

 clearInterval(self.interval);
 self.enterflg = false;
}



/*==========================================
  KVの黒・白色判定とslickの設定
==========================================*/
function TopKV($header, $kvSlider, $blandlogo, blandlogoChildrenClass, $BtnSpan,kvblack,blackActiveClassName,logoActiveClassName){
  var self = this;
  this.$header = $header;
  this.$kvSlider = $kvSlider;
  if($kvSlider.children().length){
    this.$kvSliderChild = $kvSlider.children();
  }else{
    this.$kvSliderChild = 0;
  }
  this.$blandlogo = $blandlogo;
  this.blandlogoChildrenClass = blandlogoChildrenClass;
  this.$BtnSpan = $BtnSpan;
  this.blandlogoItemLogo = [];
  this.kvblack = kvblack;
  this.blackActiveClassName = blackActiveClassName;
  this.logoActiveClassName = logoActiveClassName;

  this.paramFun();
  this.currentFun();
  this.haderNavBtnBlack();
  //scrollとリサイズ時
  $(window).on('scroll resize',function(){
    self.haderNavBtnBlack();
  });
}




TopKV.prototype.paramFun = function () {
  var self = this;
  //header要素があるかどうか
  if($(self.$header).length){



    //ブランドのロゴがある場合
    if($(self.$blandlogo).length){
      self.$blandlogo.each(function(e){
        self.blandlogoItemLogo[e] = $(this).find(self.blandlogoChildrenClass).length;
      });
    }

    //$('.is-haderNavBtn span')が存在するか確認
    if($(self.$BtnSpan).length){
      //haderNavBtnBlackの登録
      self.haderNavBtnBlack();
    }
  }
}


TopKV.prototype.currentFun = function () {
  var self = this;
  //header要素があるかどうか
  if($(self.$header).length){

    if( $(self.$kvSliderChild[0]).hasClass(self.kvblack) ){

      //ehaderのロゴがある場合
      if( !$(self.$header).length == 0 ){
        self.$header.addClass(self.blackActiveClassName);
      }
      //ブランドのロゴがある場合
      if($(self.$blandlogo).length){
        self.$blandlogo.each(function(e){
          if(self.blandlogoItemLogo[e] > 1){
            $(this).addClass(self.logoActiveClassName);
          }
        });
      }

    }


  }
}



TopKV.prototype.haderNavBtnBlack = function () {
  var self = this;

  //headerのbottom位置
  var headerIn = self.$header.innerHeight() + self.$header.offset().top;
  //self.$headerのクラスにblackActiveClassNameがあるとき
  if(self.$header.hasClass(self.blackActiveClassName)){
    //self.$BtnSpanに対して
    self.$BtnSpan.each(function(e){
      //self.$BtnSpanの位置を算出し、headerのbottom位置を超えていないとき
      if($(this).offset().top < headerIn){
        //self.$BtnSpanにクラスblackActiveClassNameを付与
       $(this).addClass(self.blackActiveClassName);

        //self.$BtnSpanの位置を算出し、headerのbottom位置を超えているとき
      }else{
        //self.$BtnSpanにクラスblackActiveClassNameがあるとき
        if($(this).hasClass(self.blackActiveClassName)){
         //self.$BtnSpanにクラスblackActiveClassNameを削除
          $(this).removeClass(self.blackActiveClassName);
        }
      }
    });
    self.$header.find('.haderNavBtn').addClass(self.blackActiveClassName);

  }else{
    //self.$BtnSpanに対して
    self.$BtnSpan.each(function(e){
      //self.$BtnSpanにクラスblackActiveClassNameがあるとき
      if($(this).hasClass(self.blackActiveClassName)){
        //self.$BtnSpanにクラスblackActiveClassNameを削除
        $(this).removeClass(self.blackActiveClassName);
      }
    });
    if(self.$header.find('.haderNavBtn').hasClass(self.blackActiveClassName)){
      self.$header.find('.haderNavBtn').removeClass(self.blackActiveClassName);
    }
    

  }
}

TopKV.prototype.slickFun = function () {

  var self = this;
  if( self.$kvSlider.length ){

    $(self.$kvSlider).on('init', function(event, slick){
      self.paramFun();
      if($('.page.top').length){
        //すべてのdotにラッパー要素/svg追加
        $('.slick-dots li button').wrap('<span class="dot-progress"></span>').after(
          '<svg width="30px" height="30px" viewBox="0 0 29.95 29.95">'
            +'<path opacity="0.2" fill-rule="evenodd" stroke="rgb(0, 0, 0)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M15,.5A14.48,14.48,0,1,1,.5,15,14.47,14.47,0,0,1,15,.5Z" />'
          +'</svg>'
        );
        //最初のdotにアニメーション用svgを追加
        $('.slick-dots li:first-child svg').after(
          '<svg width="30px" height="30px" viewBox="0 0 29.95 29.95">'
              +'<path fill-rule="evenodd"  stroke="rgb(0, 0, 0)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M15,.5A14.48,14.48,0,1,1,.5,15,14.47,14.47,0,0,1,15,.5Z" />'
          +'</svg>'
        );
        //動的にstroke-dashoffsetを設定
        $('.slick-dots li:first-child').find('svg:last path').css('stroke-dashoffset','95px');//95
        $('.slick-dots li:first-child').find('svg:last path').animate({strokeDashoffset: 0}, 3500, 'linear');
        // $('.slick-dots li:first-child').find('svg:last path').animate({strokeDashoffset: 190}, 500, 'swing');
      }
    });

    var kvSlider = $(self.$kvSlider).slick({
      autoplay: true, //自動再生
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      swipe: false,
      autoplaySpeed: 3500,
      speed: 500,
      dots: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false
    });

    //トップページの場合
    // let $activeDot = $('.slick-dots .slick-active button');
    // if($('.page.top').length){
    //   $activeDot.wrap('<span class="dot-progress"></span>');
    //   function start() {
    //     reset();
    //     percentTime = 0;
    //     run = setInterval(interval, 10);
    //   }
    //   function interval() {
    //       percentTime += 0.4;
    
    //       progress.css({
    //         width: percentTime+"%"
    //       });
    //       if(percentTime >= 100) {
    //         slick.slick('slickNext');
    //         start();
    //       }
    //   }
    //   function reset() {
    //     progress.css({
    //      width: 0+'%'
    //     });
    //     clearTimeout(run);
    //   }
    //   start();


    //is-kvSliderが切り替わった時に
    kvSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      if($('.page.top').length){
        $('.slick-dots li').eq(currentSlide).find('svg').last().remove();
      }
      if($(slick.$slides[nextSlide]).find('.'+self.kvblack).length){
        if( !$(self.$header).length == 0 ){
          self.$header.addClass(self.blackActiveClassName);
        }

        if( !$(self.$header).length == 0 ){
          $(self.$blandlogo).each(function(e){
            if(self.blandlogoItemLogo[e] > 1){
              $(this).addClass(self.logoActiveClassName);
            }
          });
        }

      }else{
        if( !$(self.$header).length == 0 ){
          self.$header.removeClass(self.blackActiveClassName);
        }

        if( !$(self.$blandlogo).length == 0 ){
          if( $(self.$blandlogo).hasClass(self.logoActiveClassName) ){
            $(self.$blandlogo).removeClass(self.logoActiveClassName);
          }
        }

      }

      self.haderNavBtnBlack();
    });
    //切り替え後
    kvSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
      if($('.page.top').length){
        $('.slick-dots li').eq(currentSlide).find('svg').after(
          '<svg width="30px" height="30px" viewBox="0 0 29.95 29.95">'
              +'<path fill-rule="evenodd"  stroke="rgb(0, 0, 0)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M15,.5A14.48,14.48,0,1,1,.5,15,14.47,14.47,0,0,1,15,.5Z" />'
          +'</svg>'
          );
        //動的にstroke-dashoffsetを設定
        $('.slick-dots li').eq(currentSlide).find('svg:last path').css('stroke-dashoffset','95px');//95
        $('.slick-dots li').eq(currentSlide).find('svg:last path').animate({strokeDashoffset: 0}, 3500, 'linear');
        // $('.slick-dots li').eq(currentSlide).find('svg:last path').animate({strokeDashoffset: 190}, 500, 'swing');
        

      }
    });
  }
}